import React from 'react';
import './App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExploreIcon from '@material-ui/icons/Explore';

function App() {
  return (
  <AppBar position="sticky">
    <Toolbar>
      <IconButton edge="start" color="inherit" aria-label="menu">
        <ExploreIcon />
      </IconButton>
      <Typography variant="h6">
        TaaS Playground Status Dashboard
      </Typography>
    </Toolbar>
  </AppBar>
  );
}

export default App;
