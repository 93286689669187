import React, { useEffect, useState } from 'react';
import { JSONRPCClient } from 'json-rpc-2.0';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

/* Icons */
import ErrorIcon from '@material-ui/icons/Error';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


interface BlockChainInfo {
  chain :string,
  mode :string,
  block :number,
  headers :number,
  bestbockhash :string,
  verificationprogress :number,
  initialblockdownload :boolean,
  size_on_disk :number,
  pruned :boolean,
  aggregatePubkeys :[],
  warnings :string,
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.primary,
    },
    heading: {
      marginLeft: theme.spacing(1),
    },
    operational: {
      backgroundColor: theme.palette.success.main,
      color: "white",
    },
    down: {
      backgroundColor: theme.palette.error.dark,
      color: "white",
    },
    toolbar: theme.mixins.toolbar,
  }),
);

const BlockChainInfo  = () => {
  const classes = useStyles();
  const [isLive, setIsLive] = useState<boolean>(false);
  const [blockchaininfo, setBlockChainInfo] = useState<BlockChainInfo>();
  const [listwallets, setListWallets] = useState([]);



  useEffect(() => {
      client.request("getblockchaininfo")
      .then(
        (result) => {
         setIsLive(true);
         setBlockChainInfo(result)
         console.log(result);
        } ,
        reason => {
          setIsLive(false); console.log("ERROR!!!")
        });

  }, []);

  function handleWalletListChange(e :object, expanded: boolean) {
    if (expanded) {
      client.request("listwallets")
      .then(
        (result) => {
          setListWallets(result.sort().filter(Boolean));
        }
      );
    }
  }

  function statusBar(isLive: boolean) {

    var text: string = "";

    if (isLive) {
      text = "Everything is operatonal"
    } else {
      text = "Something is going wrong";
    }

      return (
        <Paper
         elevation={1}
         className={classes.paper + ' ' + (isLive ? classes.operational : classes.down )}
        >
          <Grid container direction="row">
          <Grid item>
            { isLive ? <CheckCircleIcon /> : <ErrorIcon />}
          </Grid>
          <Grid item>
            <Typography
             variant="button"
             className={classes.heading}
            >
              {text}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      );
  }

  return (
    <Container>
      <div className={classes.toolbar} />
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {statusBar(isLive)}
          </Grid>

          <Grid item xs={12}>
            <Accordion
              onChange={handleWalletListChange}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                 <AccountBalanceWalletIcon />
                 <Typography className={classes.heading} variant="button">
                  Wallets
                 </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {listwallets.map((e) => <ListItem><Typography variant="body1">{e}</Typography></ListItem>)}
                </List>
              </AccordionDetails>

            </Accordion>
          </Grid>

        </Grid>
      </div>
    </Container>
  );
}

const client: JSONRPCClient = new JSONRPCClient((jsonRPCRequest) =>
  fetch("https://playground.taas.haw.biz:2820/", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "authorization": "Basic dGFweXJ1czp0YXB5cnVz"
    },
    body: JSON.stringify(jsonRPCRequest),
  }).then((response) => {
    if (response.status === 200) {
      return response
        .json()
        .then((jsonRPCResponse) => client.receive(jsonRPCResponse));
    } else if (jsonRPCRequest.id !== undefined) {
      return Promise.reject(new Error(response.statusText));
    }
  })
);

export default BlockChainInfo;